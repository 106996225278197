export const planos = [
  {
    tipo: "fibra",
    plano: 30,
    valor: "79",
    cidade: "Ipaba, MG",
    ipfixo: false,
  },
  {
    tipo: "fibra",
    plano: 50,
    valor: "129",
    cidade: "Ipaba, MG",
    ipfixo: true,
  },
  {
    tipo: "fibra",
    plano: 100,
    valor: "149",
    cidade: "Ipaba, MG",
    ipfixo: true,
  },
  {
    tipo: "fibra",
    plano: 200,
    valor: "169",
    cidade: "Ipaba, MG",
    ipfixo: true,
  },
  {
    tipo: "fibra",
    plano: 300,
    valor: "229",
    cidade: "Ipaba, MG",
    ipfixo: true,
  },
  {
    tipo: "fibra",
    plano: 30,
    valor: "79",
    cidade: "Periquito, MG",
    ipfixo: false,
  },
  {
    tipo: "fibra",
    plano: 50,
    valor: "129",
    cidade: "Periquito, MG",
    ipfixo: true,
  },
  {
    tipo: "fibra",
    plano: 100,
    valor: "149",
    cidade: "Periquito, MG",
    ipfixo: true,
  },
  {
    tipo: "fibra",
    plano: 200,
    valor: "169",
    cidade: "Periquito, MG",
    ipfixo: true,
  },
  {
    tipo: "fibra",
    plano: 300,
    valor: "229",
    cidade: "Periquito, MG",
    ipfixo: true,
  },
  {
    tipo: "rádio",
    plano: 3,
    valor: "59",
    cidade: "Naque, MG",
    ipfixo: false,
  },
  {
    tipo: "rádio",
    plano: 5,
    valor: "79",
    cidade: "Naque, MG",
    ipfixo: false,
  },
  {
    tipo: "rádio",
    plano: 7,
    valor: "99",
    cidade: "Naque, MG",
    ipfixo: false,
  },
  {
    tipo: "rádio",
    plano: 3,
    valor: "59",
    cidade: "Perpétuo Socorro, MG",
    ipfixo: false,
  },
  {
    tipo: "rádio",
    plano: 5,
    valor: "79",
    cidade: "Perpétuo Socorro, MG",
    ipfixo: false,
  },
  {
    tipo: "rádio",
    plano: 7,
    valor: "99",
    cidade: "Perpétuo Socorro, MG",
    ipfixo: false,
  },
  {
    tipo: "rádio",
    plano: 3,
    valor: "59",
    cidade: "Belo Oriente, MG",
    ipfixo: false,
  },
  {
    tipo: "rádio",
    plano: 5,
    valor: "79",
    cidade: "Belo Oriente, MG",
    ipfixo: false,
  },
  {
    tipo: "rádio",
    plano: 7,
    valor: "99",
    cidade: "Belo Oriente, MG",
    ipfixo: false,
  },
  {
    tipo: "rádio",
    plano: 3,
    valor: "59",
    cidade: "Vale Verde, MG",
    ipfixo: false,
  },
  {
    tipo: "rádio",
    plano: 5,
    valor: "79",
    cidade: "Vale Verde, MG",
    ipfixo: false,
  },
  {
    tipo: "rádio",
    plano: 7,
    valor: "99",
    cidade: "Vale Verde, MG",
    ipfixo: false,
  },
  {
    tipo: "rádio",
    plano: 3,
    valor: "59",
    cidade: "Boachá, MG",
    ipfixo: false,
  },
  {
    tipo: "rádio",
    plano: 5,
    valor: "79",
    cidade: "Boachá, MG",
    ipfixo: false,
  },
  {
    tipo: "rádio",
    plano: 7,
    valor: "99",
    cidade: "Boachá, MG",
    ipfixo: false,
  },
];
