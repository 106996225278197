export const planos = [
  {
    tipo: "fibra",
    plano: 200,
    valor: "79",
    cidade: "Ipaba, MG",
    qualifica: true,
    apps: 1,
  },
  {
    tipo: "fibra",
    plano: 400,
    valor: "99",
    cidade: "Ipaba, MG",
    qualifica: true,
    apps: 2,
  },
  {
    tipo: "fibra",
    plano: 600,
    valor: "139",
    cidade: "Ipaba, MG",
    qualifica: true,
    apps: 3,
  },
  {
    tipo: "fibra",
    plano: 800,
    valor: "169",
    cidade: "Ipaba, MG",
    qualifica: true,
    apps: 4,
  },
  {
    tipo: "fibra",
    plano: 200,
    valor: "79",
    cidade: "Periquito, MG",
    qualifica: true,
    apps: 1,
  },
  {
    tipo: "fibra",
    plano: 400,
    valor: "99",
    cidade: "Periquito, MG",
    qualifica: true,
    apps: 2,
  },
  {
    tipo: "fibra",
    plano: 600,
    valor: "139",
    cidade: "Periquito, MG",
    qualifica: true,
    apps: 3,
  },
  {
    tipo: "fibra",
    plano: 800,
    valor: "169",
    cidade: "Periquito, MG",
    qualifica: true,
    apps: 4,
  },
  {
    tipo: "fibra",
    plano: 200,
    valor: "79",
    cidade: "Naque, MG",
    qualifica: true,
    apps: 1,
  },
  {
    tipo: "fibra",
    plano: 400,
    valor: "99",
    cidade: "Naque, MG",
    qualifica: true,
    apps: 2,
  },
  {
    tipo: "fibra",
    plano: 600,
    valor: "139",
    cidade: "Naque, MG",
    qualifica: true,
    apps: 3,
  },
  {
    tipo: "fibra",
    plano: 800,
    valor: "169",
    cidade: "Naque, MG",
    qualifica: true,
    apps: 4,
  },
  {
    tipo: "fibra",
    plano: 200,
    valor: "79",
    cidade: "Perpétuo Socorro, MG",
    qualifica: true,
    apps: 1,
  },
  {
    tipo: "fibra",
    plano: 400,
    valor: "99",
    cidade: "Perpétuo Socorro, MG",
    qualifica: true,
    apps: 2,
  },
  {
    tipo: "fibra",
    plano: 600,
    valor: "139",
    cidade: "Perpétuo Socorro, MG",
    qualifica: true,
    apps: 3,
  },
  {
    tipo: "fibra",
    plano: 800,
    valor: "169",
    cidade: "Perpétuo Socorro, MG",
    qualifica: true,
    apps: 4,
  },
  {
    tipo: "fibra",
    plano: 200,
    valor: "79",
    cidade: "Belo Oriente, MG",
    qualifica: true,
    apps: 1,
  },
  {
    tipo: "fibra",
    plano: 400,
    valor: "99",
    cidade: "Belo Oriente, MG",
    qualifica: true,
    apps: 2,
  },
  {
    tipo: "fibra",
    plano: 600,
    valor: "139",
    cidade: "Belo Oriente, MG",
    qualifica: true,
    apps: 3,
  },
  {
    tipo: "fibra",
    plano: 800,
    valor: "169",
    cidade: "Belo Oriente, MG",
    qualifica: true,
    apps: 4,
  },
  {
    tipo: "fibra",
    plano: 200,
    valor: "79",
    cidade: "Vale Verde, MG",
    qualifica: true,
    apps: 1,
  },
  {
    tipo: "fibra",
    plano: 400,
    valor: "99",
    cidade: "Vale Verde, MG",
    qualifica: true,
    apps: 2,
  },
  {
    tipo: "fibra",
    plano: 600,
    valor: "139",
    cidade: "Vale Verde, MG",
    qualifica: true,
    apps: 3,
  },
  {
    tipo: "fibra",
    plano: 800,
    valor: "169",
    cidade: "Vale Verde, MG",
    qualifica: true,
    apps: 4,
  },
  {
    tipo: "fibra",
    plano: 200,
    valor: "79",
    cidade: "Boachá, MG",
    qualifica: true,
    apps: 1,
  },
  {
    tipo: "fibra",
    plano: 400,
    valor: "99",
    cidade: "Boachá, MG",
    qualifica: true,
    apps: 2,
  },
  {
    tipo: "fibra",
    plano: 600,
    valor: "139",
    cidade: "Boachá, MG",
    qualifica: true,
    apps: 3,
  },
  {
    tipo: "fibra",
    plano: 800,
    valor: "169",
    cidade: "Boachá, MG",
    qualifica: true,
    apps: 4,
  },
];
